import React, {Component} from 'react';
import Ajax from "../ajax";
import {withNamespaces} from "react-i18next";
import storage from '../storage';

class CheckUpdate extends Component {
    componentDidMount() {
        if (!storage.getItem('checkUpdateExpireToken')) {
            this.checkUpdate();
            storage.setItem('checkUpdateExpireToken', '1', 60*15);
        } else {
            console.log('Skipping update check.');
        }
    }

    checkUpdate() {
        console.log('Checking app update from server...');
        Ajax.get(`/?${new Date().getTime()}`).done(serverHtml => {
            const localHtml = document.querySelector('html').innerHTML;
            const serverMainScript = this.getMainScript(serverHtml);
            const localMainScript = this.getMainScript(localHtml);
            if (serverMainScript !== localMainScript) {
                document.location.reload(true);
            }
        });
    }

    getMainScript(html) {
        const htmlElement = document.createElement('html');
        htmlElement.innerHTML = html;
        const nodes = htmlElement.querySelectorAll('script');
        for (let i = 0; i < nodes.length; i++) {
            const script = nodes[i];
            const src = script.getAttribute('src');
            if (src && src.indexOf('main-') > -1) {
                return script.outerHTML;
            }
        }
    }

    render() {
        return null;
    }
}

export default withNamespaces()(CheckUpdate);