import React from 'react';
import PropTypes from 'prop-types';
import Urls from '../../../urls';
import { Link } from 'react-router-dom';
import LogoutButton from '../LogoutButton';
import { withNamespaces } from 'react-i18next';
import {
  isLoggedIn,
  isStaff,
  isSuperuser,
  AppContext,
} from '../../../context/global';
import LocaleUtils from '../../LocaleUtils';
import Config from '../../../config';
import avatar from './avatar.png';
import FreshDesk from '../../../integrations/FreshDesk';

class NavbarUserMenu extends React.Component {
  render() {
    if (!isLoggedIn()) {
      return null;
    }

    const { i18n } = this.props;

    const lastLogin = `${i18n.t('último login em')} ${LocaleUtils.calendar(
      this.context.user.last_login
    )}`;

    return (
      <ul className="nav navbar-nav navbar-right be-user-nav">
        <li
          className="dropdown"
          title={i18n.t('Menu de usuário')}
          data-toggle="tooltip"
          data-placement="bottom"
        >
          <a
            href="#"
            data-toggle="dropdown"
            role="button"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <img className="gravatar" src={avatar} width="38" height="38" />

            <span className="user-name">{this.context.user.email}</span>
          </a>
          <ul role="menu" className="dropdown-menu">
            <li title={lastLogin}>
              <div className="user-info">
                <div className="user-name">{this.context.user.email}</div>
                <div className="user-position online">{lastLogin}</div>
              </div>
            </li>
            <li>
              <Link to={Urls.documents}>
                <span className="mdi mdi-file-text" />
                &nbsp;
                {i18n.t('Meus documentos')}
              </Link>
            </li>
            <li>
              <Link to={Urls.account}>
                <i className="mdi mdi-account" />
                &nbsp;
                {i18n.t('Minha conta')}
              </Link>
            </li>

            {!Config.isBrandEnabled && (
              <li>
                <Link to={Urls.billing}>
                  <i className="mdi mdi-money" />
                  &nbsp;
                  {i18n.t('Pagamentos e vouchers')}
                </Link>
              </li>
            )}

            {!Config.isBrandEnabled && (
              <li>
                <Link to={Urls.plans}>
                  <i className="mdi mdi-shopping-cart" />
                  &nbsp;
                  {i18n.t('Preços')}
                </Link>
              </li>
            )}

            {(isStaff() || isSuperuser()) && <li className="divider" />}

            {(isStaff() || isSuperuser()) && (
              <>
                <li id="admin-menu">
                  <a href={Urls.admin}>Admin</a>
                </li>
                <li>
                  <Link to={Urls.mailSubscriber}>Mail subscriber</Link>
                </li>
                <li>
                  <Link to={Urls.mailSubscriberProfessor}>
                    Mail professor subscriber
                  </Link>
                </li>
              </>
            )}

            <li className="divider" />
            {!Config.isBrandEnabled && (
              <li>
                <a href={Urls.inviting}>
                  <span className="text-primary">
                    <span className="mdi mdi-card-giftcard" />
                    &nbsp;
                    {i18n.t('Convide seus amigos')}
                  </span>
                </a>
              </li>
            )}

            <li className="divider" />

            {!Config.isBrandEnabled && (
              <li>
                <FreshDesk
                  documentId={
                    this.props.document ? this.props.document.id : null
                  }
                />
              </li>
            )}

            <li>
              <Link to={Urls.about}>
                <i className="mdi mdi-info-outline" />
                &nbsp;
                {i18n.t('Sobre')}
              </Link>
            </li>

            <li>
              <Link to={Urls.terms}>
                <i className="mdi mdi-info-outline" />
                &nbsp;
                {i18n.t('Termos e Condições de Uso')}
              </Link>
            </li>

            <li>
              <Link to={Urls.privacy}>
                <i className="mdi mdi-info-outline" />
                &nbsp;
                {i18n.t('Política de Privacidade')}
              </Link>
            </li>

            <li className="divider" />

            <li>
              <LogoutButton />
            </li>
          </ul>
        </li>
      </ul>
    );
  }
}

NavbarUserMenu.propTypes = {
  document: PropTypes.object,
};

NavbarUserMenu.contextType = AppContext;

export default withNamespaces()(NavbarUserMenu);
