import React from 'react';
import PropTypes from 'prop-types';
import NavbarUserMenu from './NavbarUserMenu';
import NavbarRight from './NavbarRight';
import NavbarHeader from './NavbarHeader';
import CheckUpdate from '../CheckUpdate';

export default class Navbar extends React.Component {
  render() {
    return (
      <nav className="navbar navbar-default navbar-fixed-top be-top-header ">
        <div className="container-fluid">
          <NavbarHeader />
          <div className="be-right-navbar">
            {this.props.title && <div className="page-title">{this.props.title}</div>}
            <NavbarUserMenu />
            <NavbarRight />
          </div>
        </div>
        <CheckUpdate />
      </nav>
    );
  }
}

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
};
