import React, { useEffect, useState } from 'react';
import Ajax from '../common/ajax';
import Countdown, { zeroPad } from 'react-countdown';
import Config from '../config';

export default function SignupDiscount() {
  const [discount, setDiscount] = useState(null);

  useEffect(() => {
    fetchDiscount();
  }, []);

  async function fetchDiscount() {
    Ajax.get(`${Config.apiHost}discounts/signup_discount/`).then((data) => {
      setDiscount(data);
    });
  }

  if (discount && discount.seconds_till_end) {
    return (
      <span className="text-danger" style={{ cursor: 'default', fontSize: '12pt' }}>
        <b>
          <i className="icon mdi mdi-card-giftcard" /> CUPOM 10% DE DESCONTO{' '}
        </b>
        <b>
          <span style={{ borderBottom: '1px solid' }}>{discount.code}</span>
        </b>{' '}
        (acaba em{' '}
        <Countdown
          date={Date.now() + discount.seconds_till_end * 1000}
          renderer={(props) => `${zeroPad(props.minutes)}:${zeroPad(props.seconds)}min`}
          onComplete={() => setDiscount(null)}
        />
        )
      </span>
    );
  }

  return null;
}
