import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import i18next from 'i18next';
import Ajax from '../../common/ajax';
import Config from '../../config';
import { AVAILABLE_LANGUAGES } from '../../i18n';

class ChangeLanguageButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.changeLanguage = this.changeLanguage.bind(this);
    this.onLoaded = this.onLoaded.bind(this);
    this.loadedLangs = {};
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
    i18next.on('loaded', (loaded) => {
      if (this._mounted) {
        this.onLoaded(loaded);
      }
    });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  onLoaded(loaded) {
    this.loadedLangs = {
      ...this.loadedLangs,
      ...loaded,
    };
    this.setState((state) => ({ ...state, loading: false }));
  }

  changeLanguage(lang) {
    const { i18n } = this.props;

    if (!this.loadedLangs[lang]) {
      this.setState((state) => ({ ...state, loading: true }));
    }

    const url = `${Config.apiHost}change-language/`;
    Ajax.post(url, { lang }).done((response) => {
      i18n.changeLanguage(lang).then(() => {
        if (!this.loadedLangs[lang]) {
          this.setState((state) => ({ ...state, loading: false }));
        }
        document.location.reload();
      });
    });
  }

  render() {
    const { i18n } = this.props;
    const currentLang = i18n.language === 'pt-br' ? 'pt' : i18n.language;

    return (
      <li className="dropdown">
        <a
          href="#"
          data-toggle="dropdown"
          role="button"
          aria-expanded="false"
          className="dropdown-toggle"
        >
          <i className="mdi mdi-globe" /> {currentLang}{' '}
          {this.state.loading && <span>({i18n.t('traduzindo')}...)</span>}
        </a>
        <ul role="menu" className="dropdown-menu be-notifications">
          {AVAILABLE_LANGUAGES.map((lang) => {
            return (
              <li key={lang.code}>
                <a
                  role="button"
                  onClick={() => this.changeLanguage(lang.code)}
                  style={{
                    fontWeight: currentLang === lang.code ? 'bold' : '',
                  }}
                >
                  {lang.name}
                </a>
              </li>
            );
          })}
        </ul>
      </li>
    );
  }
}

export default withNamespaces()(ChangeLanguageButton);
