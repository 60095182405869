import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import LocaleUtils from "../../common/LocaleUtils";
import URLS from "../../urls";
import {withNamespaces} from "react-i18next";
import {AppContext} from "../../context/global";

class PlanExpirationButton extends React.Component {
    renderUpgradeButton() {
        const {i18n} = this.props;
        const value = this.context.upgrade_difference;
        return (
            <Link to={URLS.plans} className="btn btn-primary">
                {!value &&
                    i18n.t('Upgrade de plano')}
                {value &&
                    <span>
                        {i18n.t('Upgrade por apenas')}
                        {' '}
                        {LocaleUtils.currency(value)}
                    </span>}
            </Link>
        );
    }

    renderRenewButton() {
        const {i18n} = this.props;
        return (
            <Link
                to={URLS.plans}
                className="btn btn-warning"
                data-toggle="popover"
                title={this.getExpirationMessage()}
            >
                {i18n.t('Renove seu plano')}
            </Link>
        );
    }

    getExpirationMessage() {
        const {i18n} = this.props;
        if (this.context.expiration_date) {
            let expirationLabel;
            const fromNow = LocaleUtils.fromNow(
                this.context.expiration_date);
            const date = LocaleUtils.calendar(
                this.context.expiration_date);

            if (this.context.is_expired) {
                expirationLabel = `${i18n.t('Seu plano expirou')} ${fromNow} (${date})`;
            } else {
                expirationLabel = `${i18n.t('Seu plano expira')} ${fromNow} (${date})`;
            }

            return expirationLabel;
        }
    }

    renderButton() {
        let button = null;
        if (this.context.is_expired || this.context.is_expiration_close) {
            button = this.renderRenewButton();
        } else if (this.context.upgrade_next_plan || this.context.is_free) {
            button = this.renderUpgradeButton();
        }
        return button;
    }

    render() {
        return this.renderButton();
    }
}

PlanExpirationButton.contextType = AppContext;

PlanExpirationButton.propTypes = {
    /**
     * Current plan object. If not provided, it will be fetched.
     */
    currentPlan: PropTypes.object,
};

export default withNamespaces()(PlanExpirationButton);