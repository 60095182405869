import React from "react"
import Urls from "../../urls"
import Config from "../../config";
import {withNamespaces} from "react-i18next";

class LogoutButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: true
        };
        this.logoutCallback = this.logoutCallback.bind(this);
    }

    logoutCallback() {
        this.setState({
            ...this.state,
            authenticated: false
        });
    }

    render() {
        const {i18n} = this.props;
        if (this.state.authenticated) {
            return (
                <a role="button" href={`${Config.djangoHost}accounts/logout`}>
                    <span className="mdi mdi-sign-in"/>
                    &nbsp;
                    {i18n.t('Sair')}
                </a>
            )
        }

        window.location = `${Urls.root}`
    }
}

export default withNamespaces()(LogoutButton);